<template>
  <div>
    <!-- <mds-modal v-model="toggle" :title="operation + ' Confirmation'" action-required width="600px" >
      <template v-slot:mds-modal-actions>
          <mds-button-container right-aligned>
              <mds-button class="cancel-button" @click="cancel()" variation="secondary">Cancel</mds-button>
              <mds-button class="approve-button"  @click.prevent="sendVerdictAction()" variation="primary">{{operation}}</mds-button>
          </mds-button-container>
      </template>
        
      <mds-form>
        
        <div class="notes-section">
          <mds-textarea label="Add Notes"  v-model="notes"></mds-textarea>
        </div>

        <div class="file-upload-section">
          <label for="input" class="input-label">Add Attachment(s)</label>
          <input class="file-upload-container" type="file" ref="fileUploadContainer" hidden multiple>
          <br>
          <mds-button @click.prevent="chooseFiles()" variation="secondary" class="browse-button" ref="browseButton" v-if="!files.length">Browse</mds-button>
          <ul class="attachment-list" v-else>
            <li
              v-for="(file, index) in files" 
              :key="index"
            >
              {{ file[1].name }} {{ fileSizeCalculator(file[1].size) }}
              <mds-button class="remove-icon"
                @click="removeFile(index)" variation="icon-only" icon="remove" type="button" size="small"> remove
              </mds-button>
            </li>
          </ul>
        </div>
        
      </mds-form>
    </mds-modal> -->
    <mds-dialog v-model="toggle" width="500px" action-required :title="'Are you sure you want to '+operation.toLowerCase()+' these changes?'">
      {{messageText}}
    <!-- <template v-slot:mds-dialog-supplemental-content>
        <mds-form>
                <mds-textarea label="Add a Comment" v-model="notes" rows="11" placeholder="Enter your Comments"></mds-textarea>
        </mds-form>
    </template> -->
    <template v-slot:mds-dialog-actions-right>
        <mds-button-container right-aligned>
            <mds-button type="button" variation="secondary" @click="cancel()">No, go back to review</mds-button>
            <mds-button type="button" variation="primary"  @click.prevent="sendVerdictAction()">Yes, {{operation.toLowerCase()}} these changes</mds-button>
        </mds-button-container>
    </template>
</mds-dialog>
  </div>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button'
// import MdsModal from '@mds/modal'
// import MdsTextarea from '@mds/textarea'
// import MdsForm from '@mds/form'
import MdsDialog from '@mds/dialog';
export default {
  components: {
    MdsDialog,
    // MdsModal,
    MdsButton,
    MdsButtonContainer,
    // MdsTextarea,
    // MdsForm,
  },
  props: {
    operation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messageText:'',
      toggle: true,
      files: [],
      confirmationReceived: false,
      confirmationData: {},
    }
  },
  mounted(){
    console.log(this.operation);
    if(this.operation == 'Approve'){
      this.messageText="Approving these changes will send them to the next step in the workflow."
    }else{
      this.messageText="Rejecting these changes will send them back to the previous step in the workflow."
    }
  },
  methods: {
    cancel() {
      // this.$refs.fileUploadContainer.files = null;
      // this.files = [];
      this.confirmationReceived = false;
      this.toggle = !this.toggle;
      this.$emit('clicked', false);
    },
    closeModal() {
      this.confirmationData = {};
      this.confirmationReceived = false;
      this.toggle = !this.toggle;
      this.$emit('clicked',false);
    },
    sendVerdictAction() {
      this.$emit('userVerdictClick',this.operation)
    },
    fileSizeCalculator(size) {
      let formattedSize = size/1024/1024;
      return "("+formattedSize.toFixed(0)+"MB"+")";
    },
    chooseFiles() {
      let fileInputElement = this.$refs.fileUploadContainer;
      fileInputElement.addEventListener("change", this.fileHandler, false);
      fileInputElement.click();
    },
    fileHandler() {
      this.files = Object.entries(this.$refs.fileUploadContainer.files);
    },
    removeFile(listIndex) {
      document.activeElement.blur();

      // creating a data transfer object to copy the required files only, into input element.
      const dataTransfer = new DataTransfer();
      const input = this.$refs.fileUploadContainer;
      const { files } = input;

      // removing all the non-required files from the list.
      this.files.splice(listIndex, 1);
      
      // adding only the required files to the input element.
      for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        const file = files[fileIndex];
        if (listIndex !== fileIndex) { 
          dataTransfer.items.add(file); // here we exclude the file that matches the index.
        }
        input.files = dataTransfer.files;
      }
    }
  }
}
</script>

<style lang="scss">

  @import "../../assets/css/styles.scss";


  .dialog-heading {
    padding: 0px;
    margin: 0px;
  }

  .input-label {
    @include mds-body-text-m($bold: true);
    color: $mds-text-color-primary
  }

  .notes-section {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .browse-button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .attachment-list {
    padding: 0px;
    list-style: none;
  }

  .remove-icon {
    margin-top: 3px;
    margin-left: 5px;
    position: absolute;
  }

  .confirmation-modal-container {
    display: grid;
    justify-content: center;
    align-content: center;
  }

  .confirmation-icon-container {
    margin: auto;
    width: 70px;
    height: 70px;
  }

  .approval-confirmation-icon {
    width: 70px;
    height: 70px;
    @include mds-icon-color($mds-color-blue-41);
  }

  .reject-confirmation-icon {
    width: 70px;
    height: 70px;
    @include mds-icon-color($mds-color-red-50);
  }

  .entity-name-container {
    display: grid;
    justify-content: center;
    align-content: center;
    margin-top: 22px;
  }

  .timestamp-container {
    display: grid;
    justify-content: center;
    align-content: center;
    margin-top: 15px;
  }

  .close-button-container {
    display: grid;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  // .confirmation-modal-container .mds-section__header-container___VueMDS3Demo {
  //   margin: 0px;
  //   border: 0px;
  //   padding: 0px;
  // }
</style>