<template>
  <div id="datapoint-workflow" style="display: grid;height: fit-content;">
    <div class="editAreaHeader"  v-if="taskDetails.length != 0">
        <div>
           <h2 class="workflow-heading">{{taskDetails.workflowname}}: {{taskDetails.stepname}}</h2> 
        </div>
        <mds-layout-grid>
          <mds-row>
            <mds-col :cols="9" >
        <div style="margin-bottom:26px;">
            <mds-section class="workflow-task-details" border="primary" title="Task Details">
                <mds-layout-grid>
                    <mds-row>
                      <mds-col  :cols="3">
                        <h4 class="workflow-sub-heading">Workflow Status</h4>
                         <p class="workflow-Value">
                           {{taskDetails.health}}
                         </p>
                      </mds-col>
                      <mds-col  :cols="3">
                        <h4 class="workflow-sub-heading">Due Date</h4>
                         <p class="workflow-Value">
                           {{convertDate(taskDetails.duedate)}}
                         </p>
                      </mds-col>
                      <template  v-for="(taskDetailsList,taskDetailsKey) in payloadDetails.taskdetail">
                       <mds-col v-if="taskDetailsList.IsShow == 'true'" :cols="3" :key="taskDetailsKey">
                         <h4 class="workflow-sub-heading">{{taskDetailsList.Label}}</h4>
                          <p class="workflow-Value">
                            <span v-if="taskDetailsList.Label == 'Workflow Status'" class="health-status" :style="{
                              'height': '10px', 
                              'width': '10px', 
                              'background-color': colorCode(taskDetails.objectDetail.Health), 
                              'border-radius': '50%', 
                              'display': 'inline-block', 
                            }"
                          >
                          </span>
                           <span v-if="taskDetailsList.ValueType == 'datetime'">
                             <span v-if="taskDetailsList.Value != null && taskDetailsList.Value.length>0">
                               {{convertDateFormat(taskDetailsList.Value)}}
                             </span>
                             <span v-else>
                               {{taskDetailsList.Value}}
                             </span>
                           </span>
                           <span v-else>
                            {{taskDetailsList.Value}}
                           </span>
                          </p>
                       </mds-col>
                      </template>
                    </mds-row>
                </mds-layout-grid>
            </mds-section>
        </div>
             <div style="margin-bottom:50px;">
             <mds-section border="primary" title="For Review">
                  <approve-screen-sections class="approve-screen-section" v-if="showSections" :payLoadReviewDetails="payloadDetails.reviewdetail"  :sectionSize="6"
                    :isBold="true"></approve-screen-sections>
             </mds-section>
             <mds-section border="primary" title="Add a Comment">
               <mds-textarea v-model="notes" label="Default" rows="11" placeholder="Enter your note" hidden-label></mds-textarea>
             </mds-section>

        </div>
         </mds-col>
            <mds-col :cols="3">
              <comment-section :commentList="commentsDetailList"></comment-section>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
   
             <div style="border-top: 1px solid #CCCCCC;">
                   <div style="display:flex;align-items: center;height: 100%;justify-content: space-between;margin: 16px 0;">
                     <div style="display:flex;">
                        <mds-button-container >
                          
                          <mds-button  class="actionButton" size="medium" @click.prevent="goBack" variation="secondary">
                            Cancel
                            </mds-button>
                                    
                        </mds-button-container>
                      </div>
                      <div v-if="taskType == 'open'" style="display:flex;">
                        <mds-button-container >
                          
                          <mds-button v-for="(buttonDetails, actionIndex) in payloadDetails.actiondetail" class="actionButton" size="medium" @click.prevent="actionClicked(buttonDetails)" :key="actionIndex" :variation=" actionIndex == payloadDetails.actiondetail.length - 1 ? 'primary' : 'secondary'">
                            {{buttonDetails.Prompttext}}
                            </mds-button>
                                    
                        </mds-button-container>
                      </div>
              </div>
             </div>
    </div>
      <div class="loaderCursor" v-if="showLoader">
        <loader-component loaderSize="large"></loader-component>
      </div>
       <div class="approve-reject-modal-container">
      <approve-reject-modal 
        v-if="openModal" 
        :operation="operationName" 
        @clicked="modalClicked()"
        @userVerdictClick="userVerdictClick"
      >
      </approve-reject-modal>
    </div>
     <dialog-component ref="confirmDialogue"  ></dialog-component>
     <notification-component 
     v-if="showNotification" 
     :notificationMessage="notificationMessage" 
     :notificationType="notificationType"
     ></notification-component>
      
  </div>
</template>
<script>
import { MdsRow, MdsCol,MdsLayoutGrid } from "@mds/layout-grid";
import MdsSection from "@mds/section";
import { mapGetters, mapActions } from "vuex";
import ApproveRejectModal from '../common_components/ApproveRejectModal.vue';
import LoaderComponent from '../ui_component/loaderComponent.vue';
import payload from '../../config/payloadDetails.json'
import {submitUserVerdict} from '../../services/assigned_to_you_service'
import DialogComponent from '../ui_component/DialogComponent.vue';
import NotificationComponent from '../ui_component/notificationComponent.vue';
import ApproveScreenSections from './ApproveScreenSections.vue';
import { MdsButton,MdsButtonContainer } from "@mds/button";
import MdsTextarea from '@mds/textarea'
import CommentSection from './CommentSection.vue';
import { dateFormatterMixin } from '../../mixins/date.js';
export default {
  components: {
    MdsTextarea,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsSection,
    ApproveRejectModal,
    LoaderComponent,
    DialogComponent,
    NotificationComponent,
    ApproveScreenSections,
     MdsButton,MdsButtonContainer,
    CommentSection
  },
  mixins: [ dateFormatterMixin ],
  computed:{
     ...mapGetters("assignedToYou", [
     "getAssignedTaskDetail",
    ]),
  },
  data() {
    return {
      taskType:"open",
      commentsDetailList:[],
      showSections:false,
      showLoader:false,
      iconDetailsForNotification:'',
      notificationMessage:'',
      notificationType:'',
      iconColor:'',
      selectedEntityDataPointGroupDetails: [],
      taskDetails:[],
      payloadDetails:[],
      buttonsJson:[],
      dependencyTypes: {
        None: 0,
        Language: 1,
        Country: 2,
        Currency: 3,
        Date: 4,
        ShortDate: 5,
        Text: 6,
      },
      operationName: '',
      nextStepId:'',
      verdict:'',
      openModal: false,
      showNotification:false,
      notes:'',
      previousRoute: null,
    };
  },

  beforeRouteEnter (_, from, next) {
    next(vm => {
      vm.previousRoute = from.name;
    })
  },

  watch:{
    showNotification:function(){
      setInterval(() => {
        this.showNotification=false
        }, 3000);
    }
  },
  async mounted() {
    this.showLoader = true
    let id = await this.$route.query.id
    this.taskType= await this.$route.query.task
    if(this.taskType == undefined){
      this.taskType="open"
    }
      await this.getTaskDetails(id,this.taskType)
   this.showLoader=false
  },

  // logic to display the assigned item details in JSON
  // computed: {
  //   ...mapGetters('assignedToYou', ['getAssignedItems']),
  // },
  
  methods: {
     ...mapActions("assignedToYou", [
      "getAssignedTaskDetailList","getAssignedTaskDetailComments"
    ]),
    convertDateFormat(date){
        return this.convertUTCDate(JSON.parse(date));
      },
     colorCode(healthStatus) {
       if(healthStatus){
          if(healthStatus === "Good") {
            return "#00AF41";
          } else if(healthStatus === "Fair") {
            return "#F5C400";
          } else {
            return "#FF0000";
          }
       }
    },
    async getTaskDetails(id,taskType){
      var response=null
      var commentsResponse=null
      var assignedPayload={
        taskId:id,
        taskType:taskType
      }
      if(taskType == "completed"){
         response = await this.getAssignedTaskDetailList(assignedPayload)
          if(response.status == 200){
            this.payloadDetails=[]
            this.taskDetails=[]
            this.taskDetails=response.data
            this.payloadDetails=response.data.taskpayload;
            
            this.showSections=true
           commentsResponse = await this.getAssignedTaskDetailComments(this.taskDetails.workflowinstanceid)
          this.commentsDetailList=commentsResponse.data
          }
      }else{
        // if(this.getAssignedItems.data.list.length > 0){
          response = await this.getAssignedTaskDetailList(assignedPayload)
          if(response.status == 200){
            this.payloadDetails=[]
            this.taskDetails=[]
            this.taskDetails=response.data
            this.payloadDetails=response.data.taskpayload;
            
            this.showSections=true
            commentsResponse = await this.getAssignedTaskDetailComments(this.taskDetails.workflowinstanceid)
          this.commentsDetailList=commentsResponse.data
          }
        // }
      }
      
    },
    userVerdictClick(value){
       this.openModal = false;
       this.sendUserVerdict(value,this.notes)
    },
    async sendUserVerdict(value,comments){
      this.showLoader= true
      //  this.taskDetails[0].id
      
      payload.payloadForSubmittingUserVerdits.Id = this.taskDetails.id
       payload.payloadForSubmittingUserVerdits.Userverdict = this.verdict
        payload.payloadForSubmittingUserVerdits.NextStepId = this.nextStepId
         payload.payloadForSubmittingUserVerdits.Completioncomments=comments
      var response = await submitUserVerdict(payload.payloadForSubmittingUserVerdits)
      if(response.status == 201){
        this.iconDetailsForNotification='check'
          this.iconColor="blue"
          this.notificationMessage = response.data.message
          this.notificationType="success"
        //   this.$router.push({
        //     name: 'Assigned To You', 
        //     params: { showNotification:true,notificationMessage: this.notificationMessage,
        //     notificationType:this.notificationType}
        // });
      }else{
         this.iconDetailsForNotification= "alert"
          this.iconColor="red"
          this.notificationMessage = response.data.message
          this.notificationType="error"
          //  this.showNotification = true
      }
     
      this.showLoader=false
      await this.$refs.confirmDialogue.show({
                dialogMessage: this.notificationMessage,
                cancelButtonName:'Close',
                onCloseGoBackTO:'Assigned To You',
                iconDetails:this.iconDetailsForNotification,
                displayConfirmButtons:false,
                iconColor:this.iconColor,
                dialogSubTextMessage:this.getUTCFormatForCurrentDate()
      })
    },
    // 
    modalClicked(value) {
      this.openModal = value;
    },
    
    actionClicked(actionDetails){
        this.operationName = actionDetails.Prompttext;
        this.nextStepId = parseInt(actionDetails.Nextstep) 
        this.verdict=actionDetails.Verdict
      this.openModal = true;
    },
    //logic to display the assigned item details in JSON
    // displayItems(index) {
    //   let data = this.getAssignedItems.filter((element) => element.id == index);
    //   return data[0].id;
    // },
   
    goBack(){
      if(this.previousRoute === "Assigned To You") {
        this.$router.push({
          name: "Assigned To You",
          params: { navigatedFrom: this.$route.path },
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.workflow-heading{
    @include mds-level-2-heading();
    margin: $mds-space-2-x 0;
}
.workflow-sub-heading{
    @include mds-body-text-m();
    font-weight:bold;
    margin: $mds-space-2-x 0;
    margin-bottom: 8px;
}
.workflow-Value{
     @include mds-body-text-m();
    //  text-transform: capitalize;
}
#datapoint-workflow::v-deep .workflow-task-details.mds-section___VueMDS3Demo .mds-section__header-container___VueMDS3Demo{
    margin: 0;
}
.sub-heading {
  @include mds-level-5-heading($bold: true);
  color: $mds-text-color-primary;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.editAreaHeader {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 2%;
}
p {
  @include mds-body-text-m();
  margin: 0 !important;

}
.editAreaHeader {
   margin-top: 1%;
  margin-bottom: 1%;
}
.label{
   @include mds-body-text-m($bold: true);
}

.approve-screen-section {
  // margin-left: 16px;
  // margin-right: 16px;
}
#datapoint-workflow .footer{
  position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   text-align: center;
    height: 60px;
    background: white;
}
 #datapoint-workflow .footerText{
   @include mds-unordered-list(small);
      color: #B7B7B7;
      padding: $mds-space-2-x;
      .footerLinks{
        border-bottom: 1px solid #B7B7B7;
      }
}
</style>
