<template>
    <div id="ApproveScreenSections">
        <mds-section
        v-for="(dataPointGroup,index) in reviewdetail" 
        :key="'payload' + index"
         :collapsible="dataPointGroup.type != 1"
        :expanded="dataPointGroup.type != 1"
        :border="dataPointGroup.type != 1 ? 'primary' : 'none'"
        :title="
          dataPointGroup.type != 1
            ? dataPointGroup.Value
            : ''
        "
        :size="sectionSize"
        class="my-section-stack-spacing"
        :class="{'parent-group-class' : isBold,'child-group-class': !isBold}"
        style="margin-bottom: 2%"
        :id="dataPointGroup.Value"
      >
      <mds-table size="small">
          <mds-tr v-for="(detail,indexs) in dataPointGroup.Details" :key="'label'+indexs">
            <template v-if="indexs == 0">
              <mds-th v-if="detail.IsShow == 'true'">
                {{detail.Label}}
              </mds-th>
              <template v-for="(innerdetail,innerdetailIndex) in detail.Details"  >
                <mds-th :key="'innerdetail'+innerdetailIndex" v-if="innerdetail.IsShow == 'true'">
                  {{innerdetail.Label}}
                </mds-th>
              </template>
            </template>
          </mds-tr>
          <mds-tbody>
              <mds-tr v-for="(details,index) in dataPointGroup.Details" :key="'value'+index">
                <mds-td class="mdsTd" v-if="details.IsShow == 'true'">{{details.Value}}</mds-td>
                  <template v-for="(innerdetails,index) in details.Details" >
                    <mds-td class="mdsTd"  v-if="innerdetails.IsShow == 'true'" :key="'innerdetails'+index" :title="innerdetails.ValueType == 'date'?convertDate(innerdetails.Value):innerdetails.Value">
                      <!-- Value: "2021-08-12" -->
                      <span v-if="innerdetails.ValueType == 'date'">
                        <span v-if="innerdetails.Value != null && innerdetails.Value.length >0">
                          {{convertAdditionalSearchDate(JSON.parse(innerdetails.Value))}}
                        </span>
                        <span v-else>
                          {{innerdetails.Value}}
                        </span>
                      </span>
                      <span v-else>
                       {{innerdetails.Value}}
                      </span>

                    </mds-td>
                  </template>
              </mds-tr>
          </mds-tbody>
        </mds-table>
<!--      
      <mds-layout-grid style="width: 97%;
    margin: auto;
    margin-top: 0%;">
         <mds-row
          align-vertical="center"
          class="my-text-stack-spacing-class"
          style="margin-bottom: 5px;"
           v-for="(detail,indexs) in dataPointGroup.Details" :key="'label'+indexs"
        >
        <template v-if="indexs == 0">
          <mds-col v-if="detail.IsShow == 'true'"> 
            <p class="label">{{detail.Label}}</p>
          </mds-col>
            <template v-for="(innerdetail,innerdetailIndex) in detail.Details"  >
              <mds-col :key="'innerdetail'+innerdetailIndex" v-if="innerdetail.IsShow == 'true'">
               <p class="label" >{{innerdetail.Label}}</p> 
              </mds-col>
            </template>
        </template>
          
        </mds-row>
        
          <mds-row
          align-vertical="center"
          class="my-text-stack-spacing-class"
          style="margin-bottom: 1%"
          v-for="(details,index) in dataPointGroup.Details" :key="'value'+index"
        >
        <mds-col  v-if="details.IsShow == 'true'">
          <p style="margin:0" :title="details.Value">{{details.Value}}</p>
            </mds-col>
        <template v-for="(innerdetails,index) in details.Details" >
           <mds-col v-if="innerdetails.IsShow == 'true'" :key="'innerdetails'+index">
             <p style="margin:0" :title="innerdetails.Value">{{innerdetails.Value}}</p>
          </mds-col>
        </template>
        </mds-row>

      </mds-layout-grid> -->
      <approve-screen-sections style="margin-top: 16px;" v-if="dataPointGroup.subreviewdetail.length > 0" :payLoadReviewDetails="dataPointGroup.subreviewdetail" :sectionSize="8" :isBold="false"></approve-screen-sections>
    </mds-section>
    </div>
</template>
<script>
// import { MdsRow, MdsCol,MdsLayoutGrid } from "@mds/layout-grid";
import { MdsTable, MdsTh, MdsTr,MdsTbody, MdsTd} from '@mds/data-table-4.0.2';
import MdsSection from "@mds/section";
import { dateFormatterMixin } from '../../mixins/date.js';
export default {
    name:"ApproveScreenSections",
    components:{
        // MdsRow, MdsCol,MdsLayoutGrid,
        MdsSection,
        MdsTable, MdsTh, MdsTr,MdsTbody, MdsTd
    },
    props:["payLoadReviewDetails","sectionSize","isBold"],
    mixins: [dateFormatterMixin],
    data(){
        return{
            reviewdetail:this.payLoadReviewDetails
        }
    },
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
#ApproveScreenSections::v-deep  .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo.mds-section--expanded___VueMDS3Demo>.mds-section__header-container___VueMDS3Demo .mds-section__toggle___VueMDS3Demo {
  transform: rotate(90deg);
}
#ApproveScreenSections::v-deep  .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo>.mds-section__header-container___VueMDS3Demo .mds-section__toggle___VueMDS3Demo {
  transform: rotate(0deg);
}
#ApproveScreenSections::v-deep .parent-group-class.mds-section___VueMDS3Demo .mds-section--primary___VueMDS3Demo{
      border-top: 1px solid #808080;
}
#ApproveScreenSections::v-deep .child-group-class.mds-section___VueMDS3Demo .mds-section--primary___VueMDS3Demo{
      border-top: 1px solid #E5E5E5;
}
.mdsTd{
font-weight: 300 !important;
font-size: 14px !important;
}
</style>