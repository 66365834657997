<template>
    <div>
        <mds-section class="workflow-task-details" border="primary" title="Activity">
            <div v-if="commentsDetailsData.length>0" class="commentTopDiv">
                <div v-for="(comments,commentsIndex) in commentsDetailsData" :key="commentsIndex">
                    <hr class="seperatorTag" v-if="commentsIndex != 0">
                    <div class="creatorActionDiv">
                        <h4 class="creatorHeader">{{comments.creatorname}}</h4>
                        <p class="para">{{comments.action}}</p>
                    </div>
                    <div>
                        <p class="para">{{convertDate(comments.createdon)}}</p>
                    </div>
                    <div>
                        <p class="para">{{comments.comment}}</p>
                    </div>
                </div>
            </div>
        </mds-section>
    </div>
</template>
<script>
import MdsSection from "@mds/section";
import { dateFormatterMixin } from '../../mixins/date.js';
export default {
     components: {
         MdsSection,
     },

    mixins: [dateFormatterMixin],

     props:['commentList'],
     data(){ 
         return{
             commentsDetailsData:[],
         }
     },
     watch:{
         commentList(){
             console.log(this.commentList);
             this.commentsDetailsData=this.commentList
         }
     }
}
</script>
<style lang="scss" scoped>
.commentTopDiv{
    margin-top: 12px;
}
.seperatorTag{
    border: none;
    border-top: 1px solid #cccc;
    margin: 8px 0;
}
.creatorActionDiv{
    display: flex;
    .creatorHeader{
        margin:0;
        margin-right:5px;
    }
}
.para{
margin:0;line-height: 21px;
}
</style>